

import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'WarehousingDetail',
  filters: {
    previewList: function (value: any) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class extends Vue {
  private formData: any = {
    projectId: '',
    storageDate: '',
    storageExplain: '',
    detailedList: []
  }

  created () {
    this.loadData()
  }

  get id () {
    return this.$route.query.id as string
  }

  loadData () {
    this.$axios.get(this.$apis.material.selectYhStorageById, {
      id: this.id
    }).then(res => {
      this.formData = res
    })
  }

  // 下载
  onDownload () {
    const row = this.formData.fileList
    window.location.href = `${row[0].filePrefix + row[0].fileUrl}?attname=${row[0].fileName}`
  }
}
